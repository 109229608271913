body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
  /* max-width: 1440px;
  margin: auto; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    
}

.react-calendar__tile--active {
  background: #E6B980 !important;
  color: white;
}
.react-calendar__tile--hasActive {
  background: #E6B980 !important;
}

@font-face{
  font-family: 'Montserrat Regular';
  src: url(./assets/fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face{
  font-family: 'Montserrat Medium';
  src: url(./assets/fonts/Montserrat/Montserrat-Medium.ttf);
}

@font-face{
  font-family: 'Montserrat Bold';
  src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf);
}

@font-face{
  font-family: 'Montserrat Regular Italic';
  src: url(./assets/fonts/Montserrat/Montserrat-RegularItalic.ttf);
}

@font-face{
  font-family: 'Montserrat Thin';
  src: url(./assets/fonts/Montserrat/Montserrat-Thin.ttf);
}

@font-face{
  font-family: 'Montserrat_Alternates Bold';
  src: url(./assets/fonts/Montserrat_Alternates/MontserratAlternates-Bold.ttf);
}

@font-face{
  font-family: 'Montserrat_Alternates Bold';
  src: url(./assets/fonts/Montserrat_Alternates/MontserratAlternates-Bold.ttf);
}


